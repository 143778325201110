body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #dbe5f5;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}
