.hide-popup-container {
  background-color: #555;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;

  .confirm-container {
    width: 770px;
    border-radius: 12px;

    h1 {
      font-size: 15.4px;
      background: #000;
      padding-top: 10px;
      padding-bottom: 8px;
      border-radius: 12px 12px 0 0;

      &:before {
        background-image: url('../../assets/images/popups/screen.png');
      }

      & button.close {
        position: absolute;
        padding: 0;
        top: 12px;
        right: 12px;
        width: 16px;
        height: 16px;
        border: none;
        box-shadow: none;
        border-radius: 0;
        background-image: url('../../assets/images/popups/close-icon.png');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .content {
      border: none;
      background-color: #dbe5f5;

      p {
        font-size: 15.4px;
        margin: 15px 0;
        color: #000;
      }
    }
  }
}

.help-popup-container {
  @extend .hide-popup-container;
  background-color: rgba(0, 0, 0, .5);


  .confirm-container {
    padding-top: 30px;
    margin: 0 auto;
    position: absolute;
    top: 30px;
    left: calc(50% - 385px);

    h1 {
      cursor: move;

      &:before {
        background-image: url('../../assets/images/popups/access.png') !important;
      }
    }

    .content {
      button {
        background: linear-gradient(to bottom, #f6f6f6, #bebcbd) !important;
        background: -webkit-linear-gradient(to bottom, #f6f6f6, #bebcbd) !important;
        background: -moz-linear-gradient(to bottom, #f6f6f6, #bebcbd) !important;
      }

      ul.switch-content {
        text-align: left;
        padding-left: 8px;
        border-bottom: 1px solid #7d7d7e;

        li {
          margin-bottom: -1px;
          margin-right: 8px;
          list-style-type: none;
          display: inline-block;
          font-size: 15.4px;
          font-weight: bold;
          color: #333;
          background: rgba(0, 0, 0, .1);
          cursor: pointer;
          min-width: 110px;
          text-align: center;
          padding: 5px 5px;
          border: 1px solid rgba(0, 0, 0, .2);
          border-radius: 6.5px 6.5px 0 0;
          border-bottom: 1px solid #7d7d7e;

          &.active {
            background-color: #dbe5f5;
            border-color: #7d7d7e;
            border-bottom-color: #dbe5f5;
          }
        }
      }

      .info-container {
        text-align: left;
        margin-top: 25px;
        margin-bottom: 50px;

        p {
          font-size: 13.2px;
          margin-bottom: 20px;
        }

        ul {
          padding-left: 16px;

          li {
            margin: 8px 0;
            font-weight: 300;
            font-size: 12px;
          }
        }
      }

      .test-help-container {
        max-height: 400px;
        overflow-y: scroll;
        text-align: left;
        margin-bottom: 20px;

        img {
          width: auto;
        }

        p {
          font-size: 12px;
          line-height: 1.5;
          margin: 10px 0;

          & b {
            font-size: 13.2px;
          }

          &.title {
            margin-top: 30px;
            margin-bottom: 20px;
          }
        }

        table {
          td {
            font-size: 12px;
          }

          &.icon-buttons-container {
            margin: 20px 0;

            .icon {
              padding: 10px;
              span {
                font-size: 14.5px;
                padding: 4px 14px;
                color: #050608;
                border-radius: 6px;
                background-color:: #6aade4;
              }

              &.help {
                span {
                  position: relative;
                  padding-right: 35px;

                  &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 10px;

                    width: 16px;
                    height: 16px;
                    background-image: url('../../assets/images/help-icon.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
          }
        }
      }

      .task-help-container {
        @extend .test-help-container;
      }
    }
  }
}
